var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "boot" } },
    [
      _c("div", { attrs: { id: "boot2" } }, [
        _c("h1", [_vm._v(_vm._s(_vm.$t("bootcamp.t1")))])
      ]),
      _c(
        "b-container",
        { staticClass: "bv-example-row", attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c("b-col", { staticClass: "bg-light px-0 pb-3" }, [
                _c(
                  "h3",
                  {
                    staticStyle: { "font-weight": "bold" },
                    attrs: { id: "mpsw" }
                  },
                  [
                    _vm._v(_vm._s(_vm.$tc("bootcamp.mpsw", 1)) + " "),
                    _c("br"),
                    _vm._v(_vm._s(_vm.$tc("bootcamp.mpsw", 2)))
                  ]
                ),
                _c("br"),
                _c(
                  "p",
                  {
                    staticStyle: { "max-width": "1000px", margin: ".5rem auto" }
                  },
                  [_vm._v(_vm._s(_vm.$t("bootcamp.mpswP")))]
                )
              ])
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex flex-column justify-content-between px-0 pr-lg-2 flex-grow-1",
                  attrs: { cols: "12", lg: "6" }
                },
                [_c("Bootcamp", { attrs: { type: "fs_java" } })],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "d-flex flex-column justify-content-between px-0 pl-lg-2 flex-grow-1",
                  attrs: { cols: "12", lg: "6" }
                },
                [_c("Bootcamp", { attrs: { type: "fs_mern" } })],
                1
              ),
              _c(
                "b-toast",
                {
                  attrs: {
                    variant: "success",
                    "auto-hide-delay": 10000,
                    title: _vm.$tc("formgeneric.toast.t1")
                  },
                  model: {
                    value: _vm.showToast,
                    callback: function($$v) {
                      _vm.showToast = $$v
                    },
                    expression: "showToast"
                  }
                },
                [
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("formgeneric.toast.t2")) + " ")
                  ]),
                  _c("p", [
                    _vm._v(" " + _vm._s(_vm.$t("formgeneric.toast.t3")) + " "),
                    _c("span", [
                      _c(
                        "a",
                        {
                          staticStyle: { "text-decoration": "underline" },
                          attrs: {
                            href: "https://cloud.edu.mindhubweb.com/openhouse",
                            target: "_blank"
                          }
                        },
                        [_vm._v(" Open House ")]
                      )
                    ]),
                    _vm._v(". ")
                  ])
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "bootcamps" }, [
                _c("h6", [_vm._v(_vm._s(_vm.$t("bootcamp.footer")))])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }