var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "home" },
    [
      _c("Carousel"),
      _c("div", [
        _c("div", { attrs: { id: "banner1" } }, [
          _c("h4", [_vm._v(_vm._s(_vm.$tc("homebanner", 1)))]),
          _c("h2", {
            domProps: { innerHTML: _vm._s(_vm.$tc("homebanner", 2)) }
          })
        ])
      ]),
      _c("img", {
        attrs: {
          width: "100%",
          src: require(this.lang == "es"
            ? "../assets/calif_es.webp"
            : "../assets/calif_en.webp")
        }
      }),
      _c("Testimonials"),
      _c(
        "div",
        { staticClass: "bgk-black pb-5", attrs: { id: "banner-prensa" } },
        [
          _c("h2", { staticClass: "text-green m-0 font-weight-bolder py-3" }, [
            _vm._v(" " + _vm._s(_vm.$tc("press.banner.title")) + " ")
          ]),
          _c("div", { staticClass: "position-relative" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: {
                src: require("@/assets/prensa/banner_prensa.webp"),
                alt: "banner prensa"
              }
            }),
            _c(
              "div",
              {
                staticClass: "position-absolute w-100",
                attrs: { id: "banner-prensa" }
              },
              [
                _c(
                  "b-button",
                  {
                    staticClass: "d-bloc mx-auto",
                    attrs: { to: "/prensa", variant: "green" }
                  },
                  [_vm._v(_vm._s(_vm.$tc("press.banner.button")))]
                )
              ],
              1
            )
          ])
        ]
      ),
      _c("Empresas"),
      _c(
        "div",
        { staticClass: "bgk-black" },
        [_c("FormGenericoB2C", { attrs: { leadSource: _vm.leadSource } })],
        1
      ),
      _c(
        "b-toast",
        {
          attrs: {
            variant: "success",
            "auto-hide-delay": 10000,
            title: _vm.$tc("formgeneric.toast.t1")
          },
          model: {
            value: _vm.showToast,
            callback: function($$v) {
              _vm.showToast = $$v
            },
            expression: "showToast"
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.$t("formgeneric.toast.t2")) + " "),
          _c("p", [
            _vm._v(" " + _vm._s(_vm.$t("formgeneric.toast.t3")) + " "),
            _c("span", [
              _c(
                "a",
                {
                  staticStyle: { "text-decoration": "underline" },
                  attrs: {
                    href: "https://cloud.edu.mindhubweb.com/openhouse",
                    target: "_blank"
                  }
                },
                [_vm._v(" Open House ")]
              )
            ]),
            _vm._v(". ")
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }