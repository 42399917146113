// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/formulario.webp");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@/assets/bt1.jpg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@/assets/bt3.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.half-width[data-v-3b4000d7] {\n  width: 50%;\n}\n.error[data-v-3b4000d7] {\n  color: #dc3545;\n  font-size: 14px;\n  margin: 0px 0px 0px 3px;\n  text-align: start;\n}\n.btn-black[data-v-3b4000d7] {\n  font-size: 1.4rem;\n  padding: 5px 50px !important;\n  background-color: #000 !important;\n  color: #fff !important;\n}\n.valid-feedback[data-v-3b4000d7] {\n  display: none;\n  width: 100%;\n  margin-top: 0.25rem;\n  font-size: 100%;\n  color: #baff43;\n}\n.btn-black[data-v-3b4000d7]:hover {\n  color: #baff43 !important;\n}\n.form-container[data-v-3b4000d7] {\n  display: flex;\n  padding: 40px;\n  justify-content: center;\n  align-items: center;\n}\n.form-container-quiero-info[data-v-3b4000d7] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.form-container-java[data-v-3b4000d7] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.form-container-mern[data-v-3b4000d7] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n}\n.info-text[data-v-3b4000d7] {\n  flex: 1;\n  text-align: center;\n  max-width: 600px;\n  padding: 20px;\n  font-size: 1.4rem;\n}\n.info-text h4[data-v-3b4000d7] {\n  color: #fff;\n  font-weight: bold;\n  margin-bottom: 20px;\n}\nform[data-v-3b4000d7] {\n  flex: 1;\n  max-width: 600px;\n  padding: 20px;\n}\ninput[data-v-3b4000d7],\nselect[data-v-3b4000d7] {\n  width: 100%;\n  margin-top: 0.5rem;\n}\n.button-container[data-v-3b4000d7] {\n  text-align: right;\n}\n.blank-p[data-v-3b4000d7] {\n  height: 21px;\n  margin: 0;\n  padding: 0;\n}\n@media (max-width: 900px) {\n.form-container[data-v-3b4000d7] {\n    flex-direction: column;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n.half-width[data-v-3b4000d7] {\n    width: 100%;\n}\n}\n", ""]);
// Exports
module.exports = exports;
