var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            [
              _c(
                "b-col",
                { staticClass: "py-5 d-none d-md-block", attrs: { cols: "2" } },
                [
                  _c("img", {
                    staticStyle: { width: "50px" },
                    attrs: {
                      src: require("../assets/ilustraciones/pink_stripes.png"),
                      alt: ""
                    }
                  })
                ]
              ),
              _c(
                "b-col",
                { staticClass: "py-5", attrs: { cols: "12", md: "8" } },
                [
                  _c("h1", { staticClass: "text-pink pb-3" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.title")))
                  ]),
                  _c("p", { staticClass: "text-blue" }, [
                    _vm._v(" " + _vm._s(_vm.$tc("financing.desc", 1)) + " "),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$tc("financing.desc", 2)) + " ")
                  ])
                ]
              ),
              _c(
                "b-col",
                { staticClass: "d-none d-md-block", attrs: { cols: "2" } },
                [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/ilustraciones/frame.png"),
                      alt: ""
                    }
                  })
                ]
              )
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                _c("div", { staticClass: "py-4 px-5 mx-5" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/ilustraciones/traditional.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "bgk-blue p-3 text-white" }, [
                  _c("h3", { staticClass: "pb-3 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.trad.title")))
                  ]),
                  _c("p", { staticClass: "p-0 m-0" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.trad.desc")))
                  ])
                ]),
                _c("div", { staticClass: "py-5 px-3" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$tc("financing.trad.p")))])
                ])
              ]),
              _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                _c("div", { staticClass: "py-4 px-5 mx-5" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/ilustraciones/work.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "bgk-green p-3 text-black" }, [
                  _c(
                    "h3",
                    { staticClass: "pb-3 text-black font-weight-bold" },
                    [_vm._v(_vm._s(_vm.$tc("financing.work.title")))]
                  ),
                  _c("p", { staticClass: "p-0 m-0" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.work.desc")))
                  ])
                ]),
                _c("div", { staticClass: "py-5 px-3" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$tc("financing.work.p")))])
                ])
              ]),
              _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                _c("div", { staticClass: "py-4 px-5 mx-5" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    attrs: {
                      src: require("../assets/ilustraciones/blended.png"),
                      alt: ""
                    }
                  })
                ]),
                _c("div", { staticClass: "bgk-pink p-3 text-white" }, [
                  _c("h3", { staticClass: "pb-3 font-weight-bold" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.blend.title")))
                  ]),
                  _c("p", { staticClass: "p-0 m-0" }, [
                    _vm._v(_vm._s(_vm.$tc("financing.blend.desc")))
                  ])
                ]),
                _c("div", { staticClass: "py-5 px-3" }, [
                  _c("p", [_vm._v(_vm._s(_vm.$tc("financing.blend.p")))])
                ])
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }