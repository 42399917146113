var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "empresas" } },
    [
      _c("div", { attrs: { id: "banner2" } }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("empresas")))])
      ]),
      _vm.mq3.matches
        ? [
            _c(
              "div",
              { attrs: { id: "empresas" } },
              [
                _c("b-button", { attrs: { type: "submit", id: "logo1" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo3" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo4" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo5" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo6" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo7" } })
              ],
              1
            ),
            _c(
              "div",
              { attrs: { id: "empresas2" } },
              [
                _c("b-button", { attrs: { type: "submit", id: "logo8" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo9" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo10" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo12" } }),
                _c("b-button", { attrs: { type: "submit", id: "logo14" } })
              ],
              1
            )
          ]
        : _vm.mq5.matches
        ? [
            _c(
              "b-carousel",
              { staticClass: "empresas-carousel", attrs: { interval: 2000 } },
              [
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/01-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/03-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/12-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/04-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/05-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/06-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/07-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/08-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/09-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/10-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/14-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
        : [
            _c(
              "b-carousel",
              { staticClass: "empresas-carousel", attrs: { interval: 2000 } },
              [
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/01-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/03-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/04-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/05-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/06-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/07-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/08-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/09-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/10-1.png"),
                                alt: "empresas"
                              }
                            })
                          ]),
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/12-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                ),
                _c(
                  "b-carousel-slide",
                  [
                    _c("template", { slot: "img" }, [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-around" },
                        [
                          _c("div", [
                            _c("img", {
                              attrs: {
                                src: require("../assets/logos/14-1.png"),
                                alt: "empresas"
                              }
                            })
                          ])
                        ]
                      )
                    ])
                  ],
                  2
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }