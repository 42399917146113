var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "", id: "somos" } },
        [
          _c(
            "b-row",
            { staticClass: "heading" },
            [
              _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
                _c("h1", { staticClass: "text-white main-title" }, [
                  _vm._v(" " + _vm._s(_vm.$t("somosmh.t1")) + " ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { attrs: { id: "info" } },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticStyle: { "padding-bottom": "5%" } },
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "parrafos",
                        staticStyle: { "padding-top": "0" }
                      },
                      [
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.$t("somosmh.p1")) + " ")
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.$t("somosmh.p2")) + " ")
                        ])
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "py-5", attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "text-pink font-weight-bolder" }, [
                      _vm._v(
                        " " + _vm._s(_vm.$tc("somosmh.vision.title")) + " "
                      )
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "ceo px-5" },
                [
                  _c("b-col", [
                    _c("div", [
                      _c("img", {
                        attrs: {
                          src: require(this.lang == "es"
                            ? "../assets/somos/somos_nuestros_resultados_negro.png"
                            : "../assets/somos/somos_nuestros_resultados_INGLES_negro.png"),
                          alt: "somos_nuestros_resultados_negro"
                        }
                      })
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "py-5", attrs: { fluid: "" } },
            [
              _c(
                "b-row",
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "text-pink font-weight-bolder" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("somosmh.founders")) + " ")
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticStyle: { "padding-top": "2%" } },
                [
                  _c("b-col", [
                    _c(
                      "div",
                      {
                        staticClass: "parrafos",
                        staticStyle: { "padding-top": "0" }
                      },
                      [
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.$t("somosmh.p33")) + " ")
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.$t("somosmh.p34")) + " ")
                        ]),
                        _c("p", [
                          _vm._v(" " + _vm._s(_vm.$t("somosmh.p35")) + " ")
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "ceo px-5" },
                [
                  _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                    _c("div", { staticClass: "p-5" }, [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src: require("../assets/somos/alejandra.png"),
                          alt: "Alejandra Ripa"
                        }
                      })
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.linkedin.com/in/alejandra-ripa-a3a7056/"
                        }
                      },
                      [
                        _c("h4", [_vm._v("Alejandra Ripa")]),
                        _c("h5", [_vm._v("Founder")]),
                        _c(
                          "svg",
                          {
                            staticClass: "svg-inline--fa fa-linkedin fa-w-14",
                            attrs: {
                              width: "35",
                              height: "35",
                              "aria-hidden": "true",
                              focusable: "false",
                              "data-prefix": "fab",
                              "data-icon": "linkedin",
                              role: "img",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 448 512"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d:
                                  "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                    _c("div", { staticClass: "p-5" }, [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src: require("../assets/somos/vanessa.png"),
                          alt: "Alejandra Ripa"
                        }
                      })
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href:
                            "https://www.linkedin.com/in/vanessa-taiah-50b1147/"
                        }
                      },
                      [
                        _c("h4", [_vm._v("Vanessa Taiah")]),
                        _c("h5", [_vm._v("Founder")]),
                        _c(
                          "svg",
                          {
                            staticClass: "svg-inline--fa fa-linkedin fa-w-14",
                            attrs: {
                              width: "35",
                              height: "35",
                              "aria-hidden": "true",
                              focusable: "false",
                              "data-prefix": "fab",
                              "data-icon": "linkedin",
                              role: "img",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 448 512"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d:
                                  "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ]),
                  _c("b-col", { attrs: { cols: "12", lg: "4" } }, [
                    _c("div", { staticClass: "p-5" }, [
                      _c("img", {
                        staticClass: "img-fluid",
                        attrs: {
                          src: require("../assets/somos/patricia.png"),
                          alt: "Alejandra Ripa"
                        }
                      })
                    ]),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://www.linkedin.com/in/patriciamartucci/"
                        }
                      },
                      [
                        _c("h4", [_vm._v("Patricia Martucci")]),
                        _c("h5", [_vm._v("Founder")]),
                        _c(
                          "svg",
                          {
                            staticClass: "svg-inline--fa fa-linkedin fa-w-14",
                            attrs: {
                              width: "35",
                              height: "35",
                              "aria-hidden": "true",
                              focusable: "false",
                              "data-prefix": "fab",
                              "data-icon": "linkedin",
                              role: "img",
                              xmlns: "http://www.w3.org/2000/svg",
                              viewBox: "0 0 448 512"
                            }
                          },
                          [
                            _c("path", {
                              attrs: {
                                fill: "currentColor",
                                d:
                                  "M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"
                              }
                            })
                          ]
                        )
                      ]
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-container",
            { staticClass: "px-2 px-lg-5" },
            [
              _c(
                "b-row",
                { staticClass: "py-5" },
                [
                  _c("b-col", [
                    _c("h3", { staticClass: "text-pink font-weight-bolder" }, [
                      _vm._v(" " + _vm._s(_vm.$tc("somosmh.t3")) + " ")
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c("NuestrosMercados"),
          _c(
            "div",
            { staticStyle: { "margin-top": "5%" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "px-5 py-2 rounded-pill",
                  attrs: {
                    href: "https://cloud.edu.mindhubweb.com/empresas",
                    variant: "black"
                  }
                },
                [_vm._v(_vm._s(_vm.$t("somosmh.button")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }