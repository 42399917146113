var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-light flex-grow-1 d-flex flex-column h-100",
      attrs: { id: "bootDescriptions" }
    },
    [
      _c(
        "h3",
        {
          class: {
            bgImg: true,
            javaImg: _vm.type == "fs_java",
            mernImg: _vm.type == "fs_mern"
          }
        },
        [_vm._v(_vm._s(_vm.title1) + " "), _c("br"), _vm._v(_vm._s(_vm.title2))]
      ),
      _c("br"),
      _c(
        "div",
        {
          staticClass:
            "px-5 d-flex flex-column flex-grow-1 justify-content-between"
        },
        [
          _c("p", {
            staticClass: "px-6 text-left",
            domProps: { innerHTML: _vm._s(_vm.description) }
          }),
          _vm.type != "fs_java"
            ? [
                _c("img", {
                  attrs: {
                    src: require(this.lang == "es"
                      ? "../assets/parche__corregido_ES.png"
                      : "../assets/parche__corregido_EN.png"),
                    alt: "Imagen"
                  }
                }),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "px-5 py-2 rounded-pill blueButton",
                            attrs: {
                              href:
                                _vm.type == "fs_java"
                                  ? "https://cloud.edu.mindhubweb.com/inscripcion?cohortId=701Qr000009V0PyIAK&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content="
                                  : "https://cloud.edu.mindhubweb.com/openhouse",
                              variant: "dark"
                            }
                          },
                          [_vm._v(_vm._s(_vm.$tc("bootcamp.goOpenHouse")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-row",
                  [
                    _c(
                      "b-col",
                      [
                        _c(
                          "b-button",
                          {
                            staticClass: "px-4 py-2 rounded-pill",
                            attrs: {
                              id: "download-" + _vm.type + "-btn",
                              variant: "dark"
                            },
                            on: {
                              click: function($event) {
                                return _vm.showModal()
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$tc("bootcamp.download")))]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _c(
                "b-container",
                { staticClass: "pr-md-5" },
                [
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 mb-3" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("b-form-select", {
                            attrs: { options: _vm.options },
                            model: {
                              value: _vm.selected,
                              callback: function($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 text-left mb-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          [
                            _c("b-icon-calendar3", {
                              staticClass: "text-blue"
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("bootcamp.date")) + ": "
                            ),
                            _vm.selected != null
                              ? [_vm._v(" " + _vm._s(_vm.bootcamp.date) + " ")]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 text-left mb-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          [
                            _c("b-icon-stopwatch", {
                              staticClass: "text-blue"
                            }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("bootcamp.duration")) + ": "
                            ),
                            _vm.selected != null
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.bootcamp.totalHours) +
                                      "hs "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 text-left mb-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          [
                            _c("b-icon-globe", { staticClass: "text-blue" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("bootcamp.modality")) + ": "
                            ),
                            _vm.selected != null
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$tc(
                                          "modalities." + _vm.bootcamp.modality
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 text-left mb-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          [
                            _c("b-icon-clock", { staticClass: "text-blue" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("bootcamp.schedule")) + ": "
                            ),
                            _vm.selected != null
                              ? [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.fancyText(_vm.bootcamp.courseType)
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    { staticClass: "px-md-5 text-left mb-2" },
                    [
                      _c("b-col", [
                        _c(
                          "p",
                          [
                            _c("b-icon-cart2", { staticClass: "text-blue" }),
                            _vm._v(
                              " " + _vm._s(_vm.$t("bootcamp.price")) + ": "
                            ),
                            _vm.selected == "Arg"
                              ? [
                                  _vm._v(
                                    " USD " +
                                      _vm._s(
                                        _vm.bootcamp.priceAR.toLocaleString(
                                          "en"
                                        )
                                      ) +
                                      " oficiales "
                                  )
                                ]
                              : _vm.selected == "Chi"
                              ? [
                                  _vm._v(
                                    " CLP " +
                                      _vm._s(
                                        _vm.bootcamp.priceCHI.toLocaleString(
                                          "en"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm.selected == "Col"
                              ? [
                                  _vm._v(
                                    " COP " +
                                      _vm._s(
                                        _vm.bootcamp.priceCOL.toLocaleString(
                                          "en"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm.selected == "Uru"
                              ? [
                                  _vm._v(
                                    " UYU " +
                                      _vm._s(
                                        _vm.bootcamp.priceURU.toLocaleString(
                                          "en"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm.selected == "RM"
                              ? [
                                  _vm._v(
                                    " USD " +
                                      _vm._s(
                                        _vm.bootcamp.priceUSD.toLocaleString(
                                          "en"
                                        )
                                      ) +
                                      " "
                                  )
                                ]
                              : _vm._e()
                          ],
                          2
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "px-3 py-2 rounded-pill blueButton",
                              attrs: {
                                href:
                                  _vm.type == "fs_java"
                                    ? "https://cloud.edu.mindhubweb.com/inscripcion?cohortId=701Qr000009V0PyIAK&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content="
                                    : "https://cloud.edu.mindhubweb.com/inscripcion?cohortId=7018c000001hhlnAAA&utm_source=OrganicoWeb&utm_medium=webl&utm_campaign=&utm_content=",
                                variant: "dark"
                              }
                            },
                            [_vm._v(_vm._s(_vm.$tc("bootcamp.signUpNewJava")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "px-4 py-2 rounded-pill",
                              attrs: {
                                id: "download-" + _vm.type + "-btn",
                                variant: "dark"
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showModal()
                                }
                              }
                            },
                            [_vm._v(_vm._s(_vm.$tc("bootcamp.download")))]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            id: "download-" + _vm.type + "-form",
            title: "Descargar Programa",
            "hide-footer": ""
          }
        },
        [_c("FormGenericoB2C", { attrs: { leadSource: _vm.formSrc } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }