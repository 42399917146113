var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "open-house" } },
    [
      _c("div", { attrs: { id: "open-house-title" } }, [
        _c("h2", [_vm._v(_vm._s(_vm.$tc("openhouse.t1")))])
      ]),
      _c("div", { staticClass: "py-3", attrs: { id: "open-house-p" } }, [
        _c("p", [_vm._v(_vm._s(_vm.$tc("openhouse.p")))])
      ]),
      _c("div", { attrs: { id: "tdate" } }, [
        _c("p", [_vm._v(_vm._s(_vm.$tc("openhouse.tdate")))])
      ]),
      _vm.nextOpenhouse
        ? _c("div", { attrs: { id: "next-date" } }, [
            _c("p", [
              _vm._v(
                _vm._s(
                  _vm.$tc("date.day." + _vm.openHouseDate.day) +
                    " " +
                    _vm.openHouseDate.date +
                    " " +
                    _vm.$tc("date.month." + _vm.openHouseDate.month)
                ) + " "
              )
            ])
          ])
        : _c("div", [_c("p", [_vm._v(_vm._s(_vm.$tc("openhouse.data")))])]),
      _vm.nextOpenhouse
        ? _c("div", { attrs: { id: "schedules" } }, [
            _c("p", [
              _vm._v("ARG: " + _vm._s(_vm.nextOpenhouse[0].scheduleARG))
            ]),
            _c("p", [
              _vm._v("CH: " + _vm._s(_vm.nextOpenhouse[0].scheduleCHI))
            ]),
            _c("p", [
              _vm._v("CO: " + _vm._s(_vm.nextOpenhouse[0].scheduleCOL))
            ]),
            _c("p", [_vm._v("UY: " + _vm._s(_vm.nextOpenhouse[0].scheduleURU))])
          ])
        : _vm._e(),
      _vm.nextOpenhouse
        ? _c(
            "b-btn",
            {
              attrs: {
                href: "https://info.mindhubweb.com/openhouse",
                id: "btn"
              }
            },
            [_vm._v(_vm._s(_vm.$tc("openhouse.btn")))]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }