var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "pb-5", attrs: { id: "testimonials" } }, [
    _c(
      "div",
      { staticClass: "px-md-5 py-5" },
      [
        _c(
          "b-carousel",
          {
            attrs: {
              id: "testimonial-carousel",
              interval: 4000,
              controls: "",
              "img-width": "100",
              "img-height": "100"
            }
          },
          [
            _c(
              "b-carousel-slide",
              [
                _c(
                  "template",
                  { slot: "img" },
                  [
                    _c("Testimonial", {
                      attrs: {
                        name: "Agustina Chiodini",
                        stars: 5,
                        text: _vm.$tc("testimonials.t1")
                      }
                    })
                  ],
                  1
                )
              ],
              2
            ),
            _c(
              "b-carousel-slide",
              [
                _c(
                  "template",
                  { slot: "img" },
                  [
                    _c("Testimonial", {
                      attrs: {
                        name: "Sebastián Valbuena",
                        stars: 5,
                        text: _vm.$tc("testimonials.t2")
                      }
                    })
                  ],
                  1
                )
              ],
              2
            ),
            _c(
              "b-carousel-slide",
              [
                _c(
                  "template",
                  { slot: "img" },
                  [
                    _c("Testimonial", {
                      attrs: {
                        name: "Federico Val",
                        stars: 5,
                        text: _vm.$tc("testimonials.t3")
                      }
                    })
                  ],
                  1
                )
              ],
              2
            )
          ],
          1
        ),
        _vm._m(0)
      ],
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-3" }, [
      _c(
        "a",
        {
          attrs: {
            href:
              "https://www.google.com/search?q=mindhub+la&sxsrf=ALeKk02tGXOA7XrkjiWHbCLjX-Ay4aFA1g%3A1620050281414&source=hp&ei=aQGQYM_xFo3F5OUPm9yRkAY&iflsig=AINFCbYAAAAAYJAPecNKvpBJ_i4ohwGqx5uMjeugye3-&oq=min&gs_lcp=Cgdnd3Mtd2l6EAEYADIECCMQJzIECCMQJzILCAAQsQMQxwEQowIyBQguELEDMggIABCxAxCDATIFCAAQsQMyCwgAELEDEMcBEKMCMgUIABCxAzIFCC4QsQMyAgguOggILhCxAxCDAVDOCFjNCmCQG2gAcAB4AIABbYgBnAKSAQMyLjGYAQCgAQGqAQdnd3Mtd2l6&sclient=gws-wiz#lrd=0x95bccb96756ef42b:0xe81b5b162482c0cf,1,,,"
          }
        },
        [
          _c("img", {
            attrs: {
              id: "google-reviews",
              src: require("@/assets/google-reviews.webp"),
              alt: "google reviews"
            }
          })
        ]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }