var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "mx-auto testimonial-slide" }, [
    _c("h4", { staticClass: "font-weight-bolder" }, [_vm._v(_vm._s(_vm.name))]),
    _c("p", [_vm._v(" " + _vm._s(_vm.text) + " ")]),
    _c(
      "div",
      { staticClass: "w-100" },
      _vm._l(_vm.stars, function(star) {
        return _c(
          "span",
          { key: star, staticClass: "px-1" },
          [
            _c("b-icon", {
              staticClass: "text-blue",
              attrs: { icon: "star-fill", "font-scale": "1.5" }
            })
          ],
          1
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }