var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "bg-light flex-grow-1 d-flex flex-column h-100",
      attrs: { id: "bootDescriptions" }
    },
    [
      _c(
        "h3",
        {
          class: {
            bgImg: true,
            javaImg: _vm.type == "fs_java",
            mernImg: _vm.type == "fs_mern"
          }
        },
        [_vm._v(_vm._s(_vm.title1) + " "), _c("br"), _vm._v(_vm._s(_vm.title2))]
      ),
      _c("br"),
      _c(
        "div",
        {
          staticClass:
            "px-5 d-flex flex-column flex-grow-1 justify-content-between"
        },
        [
          _c("p", {
            staticClass: "px-6 text-left",
            domProps: { innerHTML: _vm._s(_vm.description) }
          }),
          _vm.type != "fs_java"
            ? void 0
            : _c("b-container", { staticClass: "pr-md-5" })
        ],
        2
      ),
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            id: "download-" + _vm.type + "-form",
            title: "Descargar Programa",
            "hide-footer": ""
          }
        },
        [_c("FormGenericoB2C", { attrs: { leadSource: _vm.formSrc } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }