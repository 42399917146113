// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/bt2.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n#mpsw{\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: cover;\n  background-position: center;\n  padding: 2em 0;\n}\n#boot button{\n  margin: 18px 0;\n}\n#boot2 h1{\n    margin-top: 20px;\n    margin-bottom: 20px;\n    text-align: center;\n    color: #FF00FF;\n    font-size: xxx-large;\n    font-weight: 900;\n}\n#boot2 p{\n    padding-left: 1.75rem;\n    padding-right: 1.75rem;\n    margin-bottom: 4em;\n}\n#boot .blueButton{\n  background-color: #0000FF;\n  border: none;\n}\n#boot h3{\n    color: #fff;\n    margin-top: 0em;\n}\n#boot{\n    background-color: white;\n    padding-bottom: 4%;\n}\n#boot .purpleButton{\n  background-color: #FF00FF;\n  border:none;\n}\n.bootcamps{\n    background-color: #0000FF;\n    color: white;\n    padding-top: 10px;\n    padding-bottom: 5px;\n    font-weight: normal;\n    text-align: center;\n}\n\n", ""]);
// Exports
module.exports = exports;
