import { render, staticRenderFns } from "./NuestrosBootcamps.vue?vue&type=template&id=671d91bf&"
import script from "./NuestrosBootcamps.vue?vue&type=script&lang=js&"
export * from "./NuestrosBootcamps.vue?vue&type=script&lang=js&"
import style0 from "./NuestrosBootcamps.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/jedify/websites/mindhub-website/front/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('671d91bf')) {
      api.createRecord('671d91bf', component.options)
    } else {
      api.reload('671d91bf', component.options)
    }
    module.hot.accept("./NuestrosBootcamps.vue?vue&type=template&id=671d91bf&", function () {
      api.rerender('671d91bf', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/NuestrosBootcamps.vue"
export default component.exports