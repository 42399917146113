var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "header",
    [
      _c(
        "b-navbar",
        {
          attrs: {
            toggleable: "lg",
            id: "nav",
            type: _vm.nav.type,
            variant: _vm.nav.variant
          }
        },
        [
          _c("b-navbar-brand", { staticClass: "ml-5", attrs: { to: "/" } }, [
            _c("img", {
              attrs: { src: require("../assets/" + _vm.nav.logo), id: "logo" }
            })
          ]),
          _c("b-navbar-toggle", { attrs: { target: "nav-collapse" } }),
          _c(
            "b-collapse",
            { attrs: { id: "nav-collapse", "is-nav": "" } },
            [
              _c(
                "b-navbar-nav",
                { staticClass: "mr-5", attrs: { align: "right" } },
                [
                  _c(
                    "b-nav-item",
                    { staticClass: "items", attrs: { to: "/somos" } },
                    [
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem1.p1"))),
                      _c("br"),
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem1.p2")))
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    { staticClass: "items", attrs: { to: "/empresas" } },
                    [
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem4.p1"))),
                      _c("br"),
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem4.p2")))
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    { staticClass: "items", attrs: { to: "/bootcamps" } },
                    [
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem2.p1"))),
                      _c("br"),
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem2.p2")))
                    ]
                  ),
                  _c(
                    "b-nav-item",
                    { staticClass: "items", attrs: { to: "/conoce-mindhub" } },
                    [
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem3.p1"))),
                      _c("br"),
                      _vm._v(_vm._s(_vm.$t("navwhite.navitem3.p2")))
                    ]
                  ),
                  _c(
                    "li",
                    { staticClass: "nav-item items", attrs: { id: "lang" } },
                    [
                      _c(
                        "div",
                        { staticClass: "nav-link" },
                        [
                          _vm._l(_vm.languages, function(language, index) {
                            return [
                              _c(
                                "span",
                                {
                                  key: language.locale,
                                  class: {
                                    "active-lang": language.locale == _vm.lang
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.changeLanguage(language.locale)
                                    }
                                  }
                                },
                                [_vm._v(" " + _vm._s(language.title) + " ")]
                              ),
                              index == 0 ? [_vm._v("|")] : _vm._e()
                            ]
                          })
                        ],
                        2
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }