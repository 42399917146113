var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "", id: "nuestros" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
                _c("h1", [
                  _vm._v(" " + _vm._s(_vm.$t("nuestrosboot.t1")) + " ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { id: "nb" } },
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            [
              _c("b-col", [
                _c("p", [_vm._v(" " + _vm._s(_vm.$t("nuestrosboot.p1")) + " ")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("Bootcamps"),
      _c("Financing"),
      _c("MetodosPago"),
      _c("Openhouse"),
      _c("h1", { staticClass: "text-pink pb-3" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.brandTitle")))
      ]),
      _c("h3", { staticClass: "text-blue" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.title")))
      ]),
      _c(
        "p",
        {
          staticClass: "px-2",
          staticStyle: { "max-width": "1000px", margin: ".5rem auto" }
        },
        [_vm._v(_vm._s(_vm.$tc("bootcamp.train")))]
      ),
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.join")))
      ]),
      _c("img", {
        staticClass: "px-6 brands-style",
        attrs: { src: require("@/assets/brands.png"), alt: "banner marcas" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }