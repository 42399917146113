var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ofrecerte" },
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticClass: "p-0 hover-black",
                  attrs: { cols: "12", lg: "4" }
                },
                [
                  _c("div", { staticClass: "ofrecerte-card card1" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card1.title")
                          )
                        }
                      }),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card1.desc")
                          )
                        }
                      })
                    ])
                  ])
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-0 hover-black",
                  attrs: { cols: "12", lg: "4" }
                },
                [
                  _c("div", { staticClass: "ofrecerte-card card2" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card2.title")
                          )
                        }
                      }),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card2.desc")
                          )
                        }
                      })
                    ])
                  ])
                ]
              ),
              _c(
                "b-col",
                {
                  staticClass: "p-0 hover-black",
                  attrs: { cols: "12", lg: "4" }
                },
                [
                  _c("div", { staticClass: "ofrecerte-card card3" }, [
                    _c("div", { staticClass: "ofrecerte-desc" }, [
                      _c("h3", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card3.title")
                          )
                        }
                      }),
                      _c("p", {
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$t("somosmh.nestrosmercados.card3.desc")
                          )
                        }
                      })
                    ])
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }