var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "", id: "nuestros" } },
        [
          _c(
            "b-row",
            [
              _c("b-col", { attrs: { cols: "12", lg: "6" } }, [
                _c("h1", [
                  _vm._v(" " + _vm._s(_vm.$t("nuestrosboot.t1")) + " ")
                ])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { id: "nb" } },
        [
          _c(
            "b-row",
            { staticClass: "py-5" },
            [
              _c("b-col", [
                _c("p", [_vm._v(" " + _vm._s(_vm.$t("nuestrosboot.p1")) + " ")])
              ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { attrs: { "align-h": "center position-relative py-5" } },
            [
              _c(
                "b-col",
                {
                  staticClass:
                    "py-5 d-flex align-items-center justify-content-center text-pink",
                  attrs: { id: "laboral-number", cols: "6", sm: "3" }
                },
                [_vm._v(" " + _vm._s(_vm.$tc("nuestrosboot.p2")) + " ")]
              ),
              _c(
                "b-col",
                {
                  staticClass: "py-5 d-flex align-items-center",
                  attrs: { cols: "6", sm: "3" }
                },
                [
                  _c(
                    "p",
                    {
                      staticClass:
                        "m-0 p-0 text-blue text-left font-weight-bold",
                      attrs: { id: "laboral-p" }
                    },
                    [
                      _vm._v(" " + _vm._s(_vm.$tc("nuestrosboot.p3", 0)) + " "),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$tc("nuestrosboot.p3", 1))),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$tc("nuestrosboot.p3", 2)) + " ")
                    ]
                  )
                ]
              ),
              _c("div", { attrs: { id: "green-left-line" } }),
              _c("div", { attrs: { id: "blue-left-line" } }),
              _c("div", { attrs: { id: "green-right-line" } }),
              _c("div", { attrs: { id: "pink-right-line" } })
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c("b-col", { staticClass: "d-flex justify-content-start" }, [
                _c("img", {
                  staticClass: "ml-5",
                  staticStyle: { width: "50px" },
                  attrs: {
                    src: require("../assets/ilustraciones/blue_stripes.png"),
                    alt: ""
                  }
                })
              ])
            ],
            1
          )
        ],
        1
      ),
      _c("Bootcamps"),
      _c("Financing"),
      _c("MetodosPago"),
      _c("Openhouse"),
      _c("h2", { staticClass: "bgk-green m-0 py-5" }, [
        _vm._v(" " + _vm._s(_vm.$tc("bootcamp.how")) + " ")
      ]),
      _c(
        "b-row",
        { staticClass: "steps my-5 px-5 mx-5" },
        [
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/uno.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [_vm._v(" " + _vm._s(_vm.$tc("bootcamp.apply")))]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.applyDescription")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/dos.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [_vm._v(" " + _vm._s(_vm.$tc("bootcamp.form")))]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.formDescription")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/tres.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [
                      _vm._v(" " + _vm._s(_vm.$tc("bootcamp.challenge")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.challengeDescription")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/cuatro.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [
                      _vm._v(" " + _vm._s(_vm.$tc("bootcamp.blended")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.blendedDescription")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/cinco.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [
                      _vm._v(" " + _vm._s(_vm.$tc("bootcamp.bootcamp")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.bootcampDescription")))
                    ])
                  ])
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-3" },
                [
                  _c("b-col", { staticClass: "col-2" }, [
                    _c("img", {
                      staticClass: "step",
                      attrs: {
                        width: "90%",
                        src: require("@/assets/seis.jpg"),
                        alt: "banner marcas"
                      }
                    })
                  ]),
                  _c("b-col", { staticClass: "col-10" }, [
                    _c("h3", [
                      _vm._v(" " + _vm._s(_vm.$tc("bootcamp.financing")))
                    ]),
                    _c("p", [
                      _vm._v(_vm._s(_vm.$tc("bootcamp.financingDescription")))
                    ])
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "my-5" },
        [
          _c(
            "b-button",
            {
              staticClass: "px-6 py-2 rounded-pill purpleButton",
              attrs: { href: "bootcamps#bootDescriptions" }
            },
            [_vm._v(_vm._s(_vm.$t("navblack.p2")))]
          )
        ],
        1
      ),
      _c("h1", { staticClass: "text-pink pb-3" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.brandTitle")))
      ]),
      _c("h3", { staticClass: "text-blue" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.title")))
      ]),
      _c(
        "p",
        {
          staticClass: "px-2",
          staticStyle: { "max-width": "1000px", margin: ".5rem auto" }
        },
        [_vm._v(_vm._s(_vm.$tc("bootcamp.train")))]
      ),
      _c("p", { staticClass: "font-weight-bold" }, [
        _vm._v(_vm._s(_vm.$tc("bootcamp.join")))
      ]),
      _c("img", {
        staticClass: "px-6 brands-style",
        attrs: { src: require("@/assets/brands.png"), alt: "banner marcas" }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }