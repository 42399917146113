var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "metodos-pago" } }, [
    _c("h1", { attrs: { id: "tituloMediodePago" } }, [
      _vm._v(_vm._s(_vm.$tc("pagos.title")))
    ]),
    _c(
      "div",
      { staticStyle: { width: "100%", overflow: "auto" } },
      [
        _c(
          "b-container",
          {
            staticStyle: {
              margin: "0 auto",
              "min-width": "960px",
              "max-width": "1280px"
            }
          },
          [
            _c(
              "b-row",
              { staticClass: "py-5 px-3", attrs: { id: "contenedorTabla" } },
              [
                _c("b-col", { attrs: { cols: "4" } }, [
                  _c("div", { attrs: { id: "titulo1" } }, [
                    _c("h6", [_vm._v(_vm._s(_vm.$tc("pagos.header1")))])
                  ]),
                  _c("div", { attrs: { id: "item" } }, [
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item1_header1")))
                    ]),
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item1_header1_part2")))
                    ])
                  ]),
                  _c("div", { attrs: { id: "item" } }, [
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item2_header1")))
                    ]),
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item2_header1_part2")))
                    ])
                  ]),
                  _c("div", { attrs: { id: "item" } }, [
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item3_header1")))
                    ]),
                    _c("p", { attrs: { id: "item-p" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.item3_header1_part2")))
                    ])
                  ])
                ]),
                _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                  _c("div", { attrs: { id: "titulo2" } }, [
                    _c("h6", [_vm._v(_vm._s(_vm.$tc("pagos.header2")))])
                  ]),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  )
                ]),
                _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                  _c("div", { attrs: { id: "titulo2" } }, [
                    _c("h6", [_vm._v(_vm._s(_vm.$tc("pagos.header3")))])
                  ]),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  )
                ]),
                _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                  _c("div", { attrs: { id: "titulo2" } }, [
                    _c("h6", [_vm._v(_vm._s(_vm.$tc("pagos.header4")))])
                  ]),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  )
                ]),
                _c("b-col", { attrs: { cols: "2", md: "2" } }, [
                  _c("div", { attrs: { id: "titulo5" } }, [
                    _c("h6", { style: { fontSize: "15px" } }, [
                      _vm._v(_vm._s(_vm.$tc("pagos.header5")))
                    ])
                  ]),
                  _c(
                    "div",
                    { attrs: { id: "item-icon" } },
                    [_c("b-icon-check-circle-fill", { staticClass: "h3" })],
                    1
                  ),
                  _c("div", { attrs: { id: "item-icon" } }, [
                    _c("p", { attrs: { id: "item-p-icon-disable" } })
                  ]),
                  _c("div", { attrs: { id: "item-icon" } }, [
                    _c("p", { attrs: { id: "item-p-icon-disable" } })
                  ])
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }