var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "carousel" } },
    [
      _c(
        "b-carousel",
        {
          staticStyle: { "text-shadow": "1px 1px 2px #333" },
          attrs: {
            id: "carousel-1",
            padding: "10",
            interval: 5000,
            fade: "",
            controls: "",
            background: "#ababab"
          },
          model: {
            value: _vm.slide,
            callback: function($$v) {
              _vm.slide = $$v
            },
            expression: "slide"
          }
        },
        [
          _c("b-carousel-slide", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              "caption-html": _vm.$t("carousel.c1"),
              "img-src": require("../assets/banner/foto1.webp")
            },
            nativeOn: {
              click: function($event) {
                return _vm.goToPage($event)
              }
            }
          }),
          _c("b-carousel-slide", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              "caption-html": _vm.$t("carousel.c2"),
              "img-src": require("../assets/banner/foto2.webp")
            },
            nativeOn: {
              click: function($event) {
                return _vm.goToPage($event)
              }
            }
          }),
          _c("b-carousel-slide", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              "caption-html": _vm.$t("carousel.c3"),
              "img-src": require("../assets/banner/foto3.webp")
            },
            nativeOn: {
              click: function($event) {
                return _vm.goToPage($event)
              }
            }
          }),
          _c("b-carousel-slide", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              "caption-html":
                "<span style='color: white;'>" +
                _vm.$t("carousel.c4") +
                "</span>",
              "img-src": require("../assets/banner/foto4.webp"),
              "caption-class": "custom-carousel-caption"
            },
            nativeOn: {
              click: function($event) {
                return _vm.goToPage($event)
              }
            }
          }),
          _c("b-carousel-slide", {
            staticStyle: { cursor: "pointer" },
            attrs: {
              "caption-html": _vm.$t("carousel.c5"),
              "img-src": require("../assets/banner/foto5.webp")
            },
            nativeOn: {
              click: function($event) {
                return _vm.goToPage($event)
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }